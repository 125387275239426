import React, { useEffect, useState } from "react";
import RevGif from "../assets/CardLogo.png";
import RevDesc from "../assets/textDescCap.png";
import { ReactComponent as TwitterLogo } from "../assets/twitterRev.svg";
import { ReactComponent as USDTLogo } from "../assets/usdt 1 (traced).svg";
import Countdown from "react-countdown";
import {
  BxlLinkedin,
  IcBaselineTelegram,
  MaterialSymbolsInfoOutline,
  MdiYoutube,
  MingcuteSocialXLine,
  RiInstagramFill,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "../assets/svg";
import {
  checkPaymentTokenAllowance,
  convertToEth,
  getStageInfo,
  approvePaymentToken,
  buyTokens,
  convertToWei,
  convertUsdtToNormalUnit,
  getPaymentTokenBalance,
  executeMulticall,
  userPaymentTokenBalance,
} from "./utils/helper";
import { useAccount } from "wagmi";
import { Watch } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ConnectBtn } from "./connectButton";
import { getContracts, MAX_BALANCE } from "./utils/contracts";

function Token() {
  const [stageInfo, setStageInfo] = useState();
  const [btnName, setBtnName] = useState("Proceed To Buy");
  const [amount, setAmount] = useState("0");
  const [address, setAddress] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dataLoader, setDataLoader] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [usdtAmount, setUsdtAmount] = useState(null);

  const [time, setTime] = useState(0);
  const [usdtFontSize, setUsdtFontSize] = useState(24);
  const [timeLabel, setTimeLabel] = useState("ends");
  const [convertedPrice, setConvertedPrice] = useState(null);
  const [totalSold, setTotalSold] = useState("0");
  const [maxToBeSold, setMaxToBeSold] = useState("0");
  const [isClaimEnable, setIsClaimEnable] = useState(false);
  const [usdtBalance, setUsdtBalance] = useState(0);
  let acc = useAccount();
  let { isConnected } = useAccount();
  let contracts = getContracts();

  useEffect(() => {
    if (isConnected == false) {
      setAddress(null);
    }
  }, [isConnected]);

  const multiCall = async () => {
    let res = await executeMulticall();
    setMaxToBeSold(res?.maxToBeSold);
    setTotalSold(res?.totalSold);
  };

  useEffect(() => {
    multiCall();
  }, []);

  function removeTrailingZeros(num) {
    // Convert the number to a string
    let str = num.toString();
    // Use a regular expression to remove trailing zeros
    str = str.replace(/(\.\d*?[1-9])0+$/g, "$1"); // Removes trailing zeros
    // Use another regular expression to remove the decimal point if it's the last character
    str = str.replace(/\.0*$/, "");
    return Number(str);
  }

  const addTokenToWallet = async () => {
    if (isClicked) {
      return;
    }
    setIsClicked(true);
    const tokenAddress = contracts.platformToken.address;
    const tokenSymbol = "ByteX";
    const tokenDecimals = 18;
    const tokenImage = "https://path-to-token-image.com/token.png";
    console.log("tokenAddress", tokenAddress)
    // Ensure window.ethereum is available
    if (!window.ethereum) {
      toast.error(
        `Ethereum provider (MetaMask) not found. Please install MetaMask.`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      setIsClicked(false);
      return;
    }

    try {
         // Request account access if needed
         const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
          params: [],
        });

        if (accounts.length > 0) {
          const wasAdded = await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: tokenAddress,
                symbol: tokenSymbol,
                decimals: tokenDecimals,
                image: tokenImage,
              },
            },
          });
    
          if (wasAdded) {
            setIsClicked(false);
            console.log("Token added to wallet!");
            toast.info(
              `Token added to wallet!`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          } else {
            setIsClicked(false);
            toast.info(
              `Token not added to wallet!`,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          }
        } else {
          alert("No accounts found.");
        }
      
    } catch (error) {
      setIsClicked(false);
      console.error("Error adding token to wallet:", error);
      // Enhanced error handling
      if (error.code === 3002) {
        console.error(
          "Error 3002: Invalid token parameters. Please check the token address, symbol, and decimals."
        );
      } else if (error.code === 4001) {
        console.error("Error 4001: The user rejected the request.");
        toast.error(
          `You have rejected the request.`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
      } else if (error.code === -32603) {
        console.error(
          "Error -32603: Internal JSON-RPC error. The wallet may not support this method."
        );
      } else {
        console.error(
          "Unexpected error adding token to wallet:",
          error.message || error
        );
      }
    }
  };

  useEffect(() => {
    if (acc?.address != null) {
      setAddress(acc?.address);
    }
  }, [acc]);

  const getActiveStageInfo = async () => {
    let info = await getStageInfo();
    console.log("info", info, Date.now());
    setStageInfo(info);
    if (
      info?.stage === 0 ||
      (info?.startTime && info?.startTime > Date.now())
    ) {
      setBtnName("No Active Sale");
    }
    if (info?.startTime && info?.startTime > Date.now()) {
      setTime(info?.startTime);
      setTimeLabel("starts");
    } else {
      setTime(info?.endTime);
      setTimeLabel("ends");
    }

    let _convertedPrice = await convertUsdtToNormalUnit(info?.price);
    setConvertedPrice(_convertedPrice);
    return info;
  };

  const getCorrespondingCxc = (amount1, price) => {
    try {
      if (usdtAmount === 0) {
        setAmount("0");
      } else if (amount1?.trim() === "" || amount1 === undefined) {
        // Change null to undefined
        setAmount("0"); // Set amount to null when amount1 is empty or undefined
        return; // Return from the function after setting amount to null
      } else {
        let cxc =
          parseFloat(amount1?.toString()) /
          parseFloat(convertUsdtToNormalUnit(price?.toString()));
        if (isNaN(cxc)) {
          setAmount("0");
        } else {
          setAmount(cxc?.toString());
        }
      }
    } catch (err) {
      console.log("error", err);
      return false;
    }
  };

  const formatNumberWithCommas = (value) => {
    if (isEmpty(value)) {
      return "";
    } else {
      let hasDecimal = "";
      if (typeof value === "number") {
        value = value.toString(); // Convert number to string
      }
      if (typeof value === "string") {
        const decimalIndex = value.indexOf(".");
        if (decimalIndex !== -1) {
          hasDecimal = value.substring(decimalIndex); // Save the decimal part
          value = value.substring(0, decimalIndex); // Remove the decimal part for processing
        }
        value = parseFloat(value.replace(/,/g, "")); // Remove existing commas and parse as float
      }
      if (isNaN(value)) {
        return "";
      }
      if (hasDecimal) {
        const decimalPart = hasDecimal.substring(1); // Remove the leading dot
        if (decimalPart.length > 3) {
          hasDecimal = "." + decimalPart.substring(0, 3);
        }
      }
      let returnValue =
        new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(value) + hasDecimal;
      return returnValue;
    }
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      await getActiveStageInfo();

      setLoading(false);
    };
    fetch();
  }, []);

  const isEmpty = (value) => {
    if (value === null || value === undefined) {
      return true;
    }
    if (typeof value === "string") {
      return value.trim() === "";
    }
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    if (typeof value === "object" && Object.keys(value).length === 0) {
      return true;
    }
    return false;
  };

  const fetchBalance = async () => {
    setLoading(true);
    await getActiveStageInfo();
    console.log(address, "address");
    const bal = await userPaymentTokenBalance(address);
    console.log("user payment token balance", bal, bal <= MAX_BALANCE);
    setUsdtBalance(bal);
    if (bal < MAX_BALANCE) {
      setIsClaimEnable(true);
    } else {
      setIsClaimEnable(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchBalance();
  }, [address]);

  const handleBuyBtnClickAction = async () => {
    setLoading(true);
    if (amount === 0 || amount === null || amount === "0") {
      toast.error("Zero amount not allowed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
      return;
    }
    let { bal, requiredBal, balRes } = await getPaymentTokenBalance(
      address,
      amount,
      stageInfo?.price
    );
    if (balRes === false) {
      toast.error(
        `Insufficient USDT, You have ${bal} balance but needed is ${requiredBal}`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
      setLoading(false);
      return;
    }
    let hasApproval = await checkPaymentTokenAllowance(
      address,
      amount,
      stageInfo?.price
    );
    console.log("hasApproval", hasApproval);
    if (!hasApproval) {
      setBtnName("Approving...");
      let res = await approvePaymentToken(amount, stageInfo?.price);
      console.log("approvePaymentToken response --", res);
      console.error(res);
      if (res === false) {
        setUsdtAmount("");
        setAmount("0");
        setBtnName("Proceed to Buy");
        setLoading(false);
        return;
      }
      setBtnName("Purchasing...");
      setTimeout(async () => {
        res = await buyTokens(amount);
        if (res === false) {
          setUsdtAmount("");
          setAmount("0");
          setBtnName("Proceed to Buy");
          setLoading(false);

          return;
        }
        setUsdtAmount("");
        setAmount("0");
        setBtnName("Proceed to Buy");
        fetchBalance();
        await getActiveStageInfo();
        await multiCall();
        setLoading(false);
      }, 1000);
    } else {
      setBtnName("Purchasing..");
      let res = await buyTokens(amount);
      if (res === false) {
        setBtnName("Proceed to Buy");
        setLoading(false);
        setUsdtAmount("");
        setAmount("0");
        return;
      }

      await getActiveStageInfo();
      await multiCall();
      setAmount(0);
      fetchBalance();
      setBtnName("Proceed to Buy");
      setUsdtAmount(0);
      setLoading(false);
    }
  };

  const Completionist = () => <span className="text-white">Sale Ended!</span>;

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    const addLeadingZero = (value) => {
      return value < 10 ? `0${value}` : value;
    };
    if (completed) {
      if (stageInfo?.startTime && stageInfo?.startTime > Date.now()) {
        setTime(stageInfo?.startTime);
        setTimeLabel("starts");
      } else if (stageInfo?.endTime && stageInfo?.endTime < Date.now()) {
        setBtnName("No Active Sale");
        return <Completionist />;
      } else {
        setTime(stageInfo?.endTime);
        setTimeLabel("ends");
      }
    } else {
      days = addLeadingZero(days);
      hours = addLeadingZero(hours);
      minutes = addLeadingZero(minutes);
      seconds = addLeadingZero(seconds);
      return (
        <>
          <div className="countdown">
            <div className="timeStamp">
              {days}:<span>Days</span>
            </div>
            <div className="timeStamp">
              {hours}:<span>Hours</span>
            </div>
            <div className="timeStamp">
              {minutes}:<span>Mins</span>
            </div>
            <div className="timeStamp">
              {seconds}
              <span>Secs</span>
            </div>
          </div>
        </>
      );
    }
  };

  useEffect(() => {
    if (
      usdtAmount &&
      usdtAmount.toString().length >= 6 &&
      usdtAmount.toString().length <= 10
    ) {
      let amountLength = usdtAmount.toString().length;
      setUsdtFontSize(26 - amountLength);
    } else if (usdtAmount && usdtAmount.toString().length >= 5) {
      setUsdtFontSize(22);
    }
  }, [usdtAmount]);

  const handleGetFunds = () => {
    window.open("https://byte-faucet.blockchainaustralia.link/", "_blank");
  };

  return (
    <div className="customContainer tokenContainer">
      {loading && (
        <div className="loaderDiv">
          <Watch
            visible={true}
            height="80"
            width="80"
            radius="48"
            color="#49C3FB"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
          <p>{btnName !== "Proceed To Buy" && btnName}</p>
        </div>
      )}
      <div className="customFlex">
        <div className="tokenContentInfo">
          <div className="mainHeadingImgDiv">
            <img src={RevDesc} alt="Token Presale" className="mainHeadingImg" />
          </div>
          {/* <div className="mainHeading">
            <h2>Token Presale</h2>
            <div className="headingShadow"><span>Token Presale</span></div>
          </div> */}
          <div
            className="d-flex flex-column align-items-start"
            style={{ gap: "36px" }}
          >
            <p>Buy in before token price increases!</p>
            <div className="socialIcons">
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <TelegramIcon />
              </a>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <YoutubeIcon />
              </a>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <TwitterLogo />
              </a>
            </div>
          </div>
        </div>
        <div className="tokenCard">
          <div className="tokenBox ">
            <div className="tokenLogo">
              {/* <div className="tokenLogoImg">REV</div> */}
              <img
                src={RevGif}
                alt="REV"
                className="img-fluid"
                style={{ maxWidth: "100px", padding: "38px 0 22px 0" }}
              />
            </div>
            <div className="tokenStage">
              <div className="tokenStageName">
                Stage{" "}
                {stageInfo && stageInfo.stage ? Number(stageInfo.stage) : "-"}
              </div>
              <div
                className="tokenBoxBg"
                style={{ padding: "0 10px 10px 15px", marginTop: "12px" }}
              >
                <div className="barBox">
                  <div className="text-start">
                    <h3 className="heading">Token Price</h3>
                    <div className="amount">
                      $
                      {stageInfo && convertedPrice
                        ? formatNumberWithCommas(
                            removeTrailingZeros(
                              Number(convertedPrice).toFixed(3)
                            )
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="text-start">
                    <h3 className="heading">Total Amount Raised</h3>
                    <div className="amount">
                      $
                      {totalSold
                        ? formatNumberWithCommas(
                            removeTrailingZeros(Number(totalSold).toFixed(2))
                          )
                        : "-"}
                      /$
                      {maxToBeSold
                        ? formatNumberWithCommas(
                            removeTrailingZeros(Number(maxToBeSold).toFixed(2))
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
                <div className="progressBar">
                  <div
                    className="progressAmount"
                    style={{
                      width: `${(parseFloat(convertToEth(stageInfo?.amountReceived ?? 0)) / parseFloat(convertToEth(stageInfo?.totalAmountToBeReceived ?? 1))) * 100}%`,
                    }}
                  >
                    <div
                      className={`percentage ${
                        removeTrailingZeros(
                          Number(
                            (
                              (parseFloat(
                                convertToEth(stageInfo?.amountReceived ?? 0)
                              ) /
                                parseFloat(
                                  convertToEth(
                                    stageInfo?.totalAmountToBeReceived ?? 1
                                  )
                                )) *
                              100
                            ).toFixed(3)
                          )
                        ) < 85
                          ? "smaller"
                          : "bigger"
                      }`}
                      style={{
                        transform:
                          removeTrailingZeros(
                            Number(
                              (
                                (parseFloat(
                                  convertToEth(stageInfo?.amountReceived ?? 0)
                                ) /
                                  parseFloat(
                                    convertToEth(
                                      stageInfo?.totalAmountToBeReceived ?? 1
                                    )
                                  )) *
                                100
                              ).toFixed(3)
                            )
                          ) < 85
                            ? "translateX(-50%)"
                            : "translateX(-100%)",
                      }}
                    >
                      {removeTrailingZeros(
                        Number(
                          (parseFloat(
                            convertToEth(stageInfo?.amountReceived ?? 0)
                          ) /
                            parseFloat(
                              convertToEth(
                                stageInfo?.totalAmountToBeReceived ?? 1
                              )
                            )) *
                            100
                        ).toFixed(3)
                      )}
                      %
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="tokenBoxBg"
                style={{ padding: "11px 10px", marginTop: "14px" }}
              >
                <div className="countDownDiv">
                  {time ? (
                    <div>
                      <h3 className="heading">
                        {" "}
                        {stageInfo && stageInfo.stage
                          ? time > Date.now()
                            ? "Stage " + Number(stageInfo.stage)
                            : ""
                          : "-"}{" "}
                        {time > Date.now() ? timeLabel + " in" : ""}{" "}
                      </h3>
                      <Countdown date={time} renderer={renderer} />
                    </div>
                  ) : (
                    <div className="text-white">No Active Sale</div>
                  )}
                </div>
              </div>
              {address && (
                <div
                  className="tokenBoxBg d-flex align-items-center justify-content-between"
                  style={{ padding: "11px 10px", marginTop: "14px" }}
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: "#fff",
                      fontWeight: "500",
                    }}
                  >
                    USDT Balance:
                  </div>
                  <div className="usdt-amount">
                    {formatNumberWithCommas(
                      removeTrailingZeros(Number(usdtBalance).toFixed(2))
                    )}
                  </div>
                </div>
              )}
              <div
                className="d-flex flex-wrap align-items-center bottomMarginDiv"
                style={{ gap: "7px" }}
              >
                <span className="mobileAmount">
                  You will get{" "}
                  {formatNumberWithCommas(parseFloat(amount)?.toFixed(3))}
                  {/* {amount
                      ? removeTrailingZeros(Number(amount).toFixed(3))
                      : ""} */}{" "}
                  ByteX
                </span>
                <div
                  className="tokenBoxBg w-100"
                  // style={{ maxWidth: "320px" }}
                >
                  <div className="tokenAmount">
                    <div className="tokenChainName barBox flex-nowrap">
                      <USDTLogo />
                      <div
                        className="heading fw-bold"
                        style={{ fontSize: "16px" }}
                      >
                        {" "}
                        USDT
                      </div>
                    </div>
                    <div className="amountInputDiv">
                      <input
                        placeholder="Enter Amount"
                        type="text"
                        value={usdtAmount}
                        onChange={async (e) => {
                          let newValue = e.target.value;

                          // Remove any characters that are not digits or a single decimal point
                          newValue = newValue.replace(/[^0-9.]/g, "");
                          const decimalCount = (newValue.match(/\./g) || [])
                            .length;
                          if (decimalCount > 1) {
                            newValue = newValue.slice(0, -1);
                          } else if (decimalCount === 1) {
                            if (newValue.startsWith(".")) {
                              newValue = "0" + newValue;
                            }
                            // Split the newValue into integer and fractional parts
                            newValue = newValue.split(".");
                            let integerPart = newValue[0];
                            let fractionalPart = newValue[1];
                            // Limit the length of the integer part to 7 digits
                            if (integerPart.length > 7) {
                              integerPart = integerPart.slice(0, 7);
                            }

                            // Limit the length of the fractional part to 3 digits
                            if (fractionalPart.length > 3) {
                              fractionalPart = fractionalPart.slice(0, 3);
                            }
                            newValue = `${integerPart}.${fractionalPart}`;
                          } else if (decimalCount === 0) {
                            if (newValue.length > 7) {
                              newValue = newValue.slice(0, 7);
                            }
                          }

                          // Reconstruct the newValue

                          // Set the value and trigger the corresponding logic
                          if (
                            Number(newValue) <=
                            Number(
                              convertToEth(stageInfo?.totalAmountToBeReceived)
                            ) -
                              Number(convertToEth(stageInfo?.amountReceived))
                          ) {
                            setUsdtAmount(newValue);
                            getCorrespondingCxc(newValue, stageInfo?.price);
                          } else {
                            e.preventDefault();
                            toast.error(
                              `Tokens Left For Sale Are ${
                                removeTrailingZeros(
                                  Number(maxToBeSold).toFixed(2)
                                ) -
                                removeTrailingZeros(
                                  Number(totalSold).toFixed(2)
                                )
                              }`
                            );
                          }
                        }}
                        disabled={
                          btnName === null ||
                          address === null ||
                          loading === true ||
                          btnName === "No Active Sale" ||
                          Number(
                            convertToEth(stageInfo?.totalAmountToBeReceived)
                          ) === Number(convertToEth(stageInfo?.amountReceived))
                        }
                      />
                      <span>
                        You will get{" "}
                        <b>
                          {formatNumberWithCommas(
                            removeTrailingZeros(parseFloat(amount)?.toFixed(3))
                          )}
                          {/* {amount
                            ? removeTrailingZeros(Number(amount).toFixed(3))
                            : ""} */}
                        </b>{" "}
                        ByteX{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <p className="addRev" onClick={addTokenToWallet}>
                  <span>+</span>Add ByteX to Metamask
                </p>
              </div>
            </div>
            <div className="tokenHeading d-flex flex-column align-items-center">
              {address !== null ? (
                <button
                  disabled={
                    btnName === null ||
                    address === null ||
                    Number(amount) === 0 ||
                    amount === null ||
                    amount === "0" ||
                    amount === "" ||
                    loading === true ||
                    btnName === "No Active Sale" ||
                    dataLoader === true
                  }
                  className="colorBtn w-100"
                  onClick={async () => {
                    handleBuyBtnClickAction();
                  }}
                >
                  {dataLoader ? "loading.." : btnName}
                </button>
              ) : (
                <ConnectBtn />
              )}
              <div
                className="disclaimer mobilrMetaMask mt-0"
                style={{ fontSize: "14px" }}
              >
                *In MetaMask, go to Settings {"->"} Advanced and disable 'Smart
                Transactions'.
              </div>
              <div
                style={{
                  display: "inline-block",
                }}
              >
                <button
                  className="linkClass"
                  disabled={!isClaimEnable || !acc.address}
                  onClick={handleGetFunds}
                >
                  Get Funds
                </button>
                {/* <div className="customTooltip mx-1">
                  <MaterialSymbolsInfoOutline />
                  <span className="tooltipText">
                    Conveniently purchase ETH and USDT with a credit card via
                    Transak
                  </span>
                </div> */}
              </div>
            </div>
            <div className="socialIcons d-lg-none">
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <TelegramIcon />
              </a>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <YoutubeIcon />
              </a>
              <a
                href="#"
                rel="noreferrer"
                target="_blank"
                onClick={(e) => e.preventDefault()}
              >
                <TwitterLogo />
              </a>
            </div>
            <div className="tokenStage">
              <div
                className="tokenStageName mx-auto"
                style={{
                  fontSize: "14px",
                  marginTop: "11px",
                  maxWidth: "max-content",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.open("/disclaimer");
                }}
              >
                Disclaimer
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Token;
