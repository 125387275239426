import React from "react";

export function MaterialSymbolsInfoOutline(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18}
      height={18}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="white"
        d="M11 17h2v-6h-2zm1-8q.425 0 .713-.288T13 8t-.288-.712T12 7t-.712.288T11 8t.288.713T12 9m0 13q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
      ></path>
    </svg>
  );
}

export function BxlLinkedin(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx={4.983} cy={5.009} r={2.188} fill="181f25"></circle>
      <path
        fill="#181f25"
        d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118c1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783c-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66zm-6.142 0H6.87v12.139H3.095z"
      ></path>
    </svg>
  );
}

export function MingcuteSocialXLine(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036c-.01-.003-.019 0-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="#181f25"
          d="M19.753 4.659a1 1 0 0 0-1.506-1.317l-5.11 5.84L8.8 3.4A1 1 0 0 0 8 3H4a1 1 0 0 0-.8 1.6l6.437 8.582l-5.39 6.16a1 1 0 0 0 1.506 1.317l5.11-5.841L15.2 20.6a1 1 0 0 0 .8.4h4a1 1 0 0 0 .8-1.6l-6.437-8.582l5.39-6.16ZM16.5 19L6 5h1.5L18 19z"
        ></path>
      </g>
    </svg>
  );
}

export function MdiYoutube(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
    >
      <path
        d="M26.4594 21.7264L21.2863 19.3126C20.8349 19.103 20.4641 19.338 20.4641 19.8378V24.3844C20.4641 24.8842 20.8349 25.1191 21.2863 24.9095L26.4571 22.4957C26.9108 22.2838 26.9108 21.9383 26.4594 21.7264ZM22.7673 0C10.5556 0 0.65625 9.89931 0.65625 22.1111C0.65625 34.3228 10.5556 44.2222 22.7673 44.2222C34.9791 44.2222 44.8784 34.3228 44.8784 22.1111C44.8784 9.89931 34.9791 0 22.7673 0ZM22.7673 31.0937C11.4492 31.0937 11.2511 30.0734 11.2511 22.1111C11.2511 14.1488 11.4492 13.1285 22.7673 13.1285C34.0854 13.1285 34.2835 14.1488 34.2835 22.1111C34.2835 30.0734 34.0854 31.0937 22.7673 31.0937Z"
        fill="white"
      />
    </svg>
  );
}

export function RiInstagramFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={44}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M13.028 2c1.125.003 1.696.009 2.189.023l.194.007c.224.008.445.018.712.03c1.064.05 1.79.218 2.427.465c.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428c.012.266.022.487.03.712l.006.194c.015.492.021 1.063.023 2.188l.001.746v1.31a78.831 78.831 0 0 1-.023 2.188l-.006.194c-.008.225-.018.446-.03.712c-.05 1.065-.22 1.79-.466 2.428a4.883 4.883 0 0 1-1.153 1.772a4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.427.465a72.11 72.11 0 0 1-.712.03l-.194.006c-.493.014-1.064.021-2.189.023l-.746.001h-1.309a78.43 78.43 0 0 1-2.189-.023l-.194-.006a63.036 63.036 0 0 1-.712-.031c-1.064-.05-1.79-.218-2.428-.465a4.889 4.889 0 0 1-1.771-1.153a4.904 4.904 0 0 1-1.154-1.772c-.247-.637-.415-1.363-.465-2.428a74.1 74.1 0 0 1-.03-.712l-.005-.194A79.047 79.047 0 0 1 2 13.028v-2.056a78.82 78.82 0 0 1 .022-2.188l.007-.194c.008-.225.018-.446.03-.712c.05-1.065.218-1.79.465-2.428A4.88 4.88 0 0 1 3.68 3.678a4.897 4.897 0 0 1 1.77-1.153c.638-.247 1.363-.415 2.428-.465c.266-.012.488-.022.712-.03l.194-.006a79 79 0 0 1 2.188-.023zM12 7a5 5 0 1 0 0 10a5 5 0 0 0 0-10m0 2a3 3 0 1 1 .001 6a3 3 0 0 1 0-6m5.25-3.5a1.25 1.25 0 0 0 0 2.5a1.25 1.25 0 0 0 0-2.5"
      ></path>
    </svg>
  );
}
export function CiHamburgerMd(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={48}
      height={48}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 17h14M5 12h14M5 7h14"
      ></path>
    </svg>
  );
}

export function MingcuteTelegramFill(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
        <path
          fill="#181f25"
          d="M19.777 4.43a1.5 1.5 0 0 1 2.062 1.626l-2.268 13.757c-.22 1.327-1.676 2.088-2.893 1.427c-1.018-.553-2.53-1.405-3.89-2.294c-.68-.445-2.763-1.87-2.507-2.884c.22-.867 3.72-4.125 5.72-6.062c.785-.761.427-1.2-.5-.5c-2.302 1.738-5.998 4.381-7.22 5.125c-1.078.656-1.64.768-2.312.656c-1.226-.204-2.363-.52-3.291-.905c-1.254-.52-1.193-2.244-.001-2.746z"
        ></path>
      </g>
    </svg>
  );
}

export function IcBaselineTelegram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={44}
      height={44}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#fff"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2m4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19c-.14.75-.42 1-.68 1.03c-.58.05-1.02-.38-1.58-.75c-.88-.58-1.38-.94-2.23-1.5c-.99-.65-.35-1.01.22-1.59c.15-.15 2.71-2.48 2.76-2.69a.2.2 0 0 0-.05-.18c-.06-.05-.14-.03-.21-.02c-.09.02-1.49.95-4.22 2.79c-.4.27-.76.41-1.08.4c-.36-.01-1.04-.2-1.55-.37c-.63-.2-1.12-.31-1.08-.66c.02-.18.27-.36.74-.55c2.92-1.27 4.86-2.11 5.83-2.51c2.78-1.16 3.35-1.36 3.73-1.36c.08 0 .27.02.39.12c.1.08.13.19.14.27c-.01.06.01.24 0 .38"
      ></path>
    </svg>
  );
}

export function UsdtIcon(props) {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 339.43 295.27"
    >
      <title>tether-usdt-logo</title>
      <path
        d="M62.15,1.45l-61.89,130a2.52,2.52,0,0,0,.54,2.94L167.95,294.56a2.55,2.55,0,0,0,3.53,0L338.63,134.4a2.52,2.52,0,0,0,.54-2.94l-61.89-130A2.5,2.5,0,0,0,275,0H64.45a2.5,2.5,0,0,0-2.3,1.45h0Z"
        style={{ fill: "#50af95", fillRule: "evenodd" }}
      />
      <path
        d="M191.19,144.8v0c-1.2.09-7.4,0.46-21.23,0.46-11,0-18.81-.33-21.55-0.46v0c-42.51-1.87-74.24-9.27-74.24-18.13s31.73-16.25,74.24-18.15v28.91c2.78,0.2,10.74.67,21.74,0.67,13.2,0,19.81-.55,21-0.66v-28.9c42.42,1.89,74.08,9.29,74.08,18.13s-31.65,16.24-74.08,18.12h0Zm0-39.25V79.68h59.2V40.23H89.21V79.68H148.4v25.86c-48.11,2.21-84.29,11.74-84.29,23.16s36.18,20.94,84.29,23.16v82.9h42.78V151.83c48-2.21,84.12-11.73,84.12-23.14s-36.09-20.93-84.12-23.15h0Zm0,0h0Z"
        style={{ fill: "#fff", fillRule: "evenodd" }}
      />
    </svg>
  );
}

export function TelegramIcon(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49" fill="none">
  <path d="M35.5108 16.8222C35.147 20.6547 33.5704 29.969 32.7699 34.2623C32.4303 36.0815 31.7512 36.6879 31.1205 36.7607C29.7137 36.882 28.6464 35.839 27.2881 34.9415C25.1535 33.5347 23.9407 32.6614 21.879 31.3031C19.4776 29.7265 21.03 28.8532 22.4126 27.4464C22.7764 27.0825 28.986 21.4309 29.1073 20.9215C29.1241 20.8444 29.1219 20.7643 29.1007 20.6882C29.0796 20.6121 29.0402 20.5423 28.986 20.4849C28.8404 20.3636 28.6464 20.4121 28.4766 20.4364C28.2583 20.4849 24.8624 22.7407 18.2405 27.2038C17.2703 27.8587 16.3971 28.1983 15.6209 28.1741C14.7477 28.1498 13.0983 27.6889 11.8612 27.2766C10.3331 26.7915 9.14453 26.5247 9.24155 25.6757C9.29007 25.2391 9.89647 24.8025 11.0365 24.3416C18.1193 21.2611 22.8249 19.2236 25.1778 18.2533C31.921 15.4396 33.3036 14.9545 34.2253 14.9545C34.4193 14.9545 34.8802 15.003 35.1713 15.2456C35.4138 15.4396 35.4866 15.7065 35.5108 15.9005C35.4866 16.046 35.5351 16.4827 35.5108 16.8222Z" fill="#49C3FB"/>
  <circle cx="24.256" cy="24.5842" r="23.574" stroke="#49C3FB" stroke-width="1.36407"/>
</svg>
  )
}

export function YoutubeIcon(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="49" viewBox="0 0 51 49" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.9435 34.4382C13.5275 34.4382 13.3102 33.3189 13.3102 24.5842C13.3102 15.8495 13.5275 14.7302 25.9435 14.7302C38.3596 14.7302 38.5769 15.8495 38.5769 24.5842C38.5769 33.3189 38.3596 34.4382 25.9435 34.4382ZM24.3189 21.5143L29.9938 24.1622C30.489 24.3947 30.489 24.7737 29.9913 25.0061L24.3189 27.6541C23.8237 27.884 23.4169 27.6263 23.4169 27.078V22.0903C23.4169 21.5421 23.8237 21.2843 24.3189 21.5143Z" fill="#49C3FB"/>
  <circle cx="25.0529" cy="24.5842" r="23.574" stroke="#49C3FB" stroke-width="1.36407"/>
</svg>
  )
}