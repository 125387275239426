import React from "react";
import { ConnectBtn } from "./connectButton";
import RevGif from "../assets/HeaderLogo.png"

function Header() {
  return (
    <header>
      <nav className="customContainer navbar navbar-expand-lg navbar-light bg-transparent">
        <div className="container-fluid">
          <a
            className="navbar-brand text-start tokenLogoImgDiv"
            // target="_blank"
            // rel="noreferrer"
            href="/"
          >
            <img src={RevGif} alt="REV" className="img-fluid" />
            {/* <div className="tokenLogoText">REV</div> */}
            {/* <Logo /> */}
          </a>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 navBtns">
              {/* <li className="nav-item">
                                <button className="customOutlineBtn" onClick={(e) => { address ? logout() : connectMetamask(e) }}>{address ? address : "Metamask"}</button>
                            </li> */}
              <li className="nav-item">
                {/* <button className="customOutlineBtn">Connect Wallet</button> */}
                <ConnectBtn />
              </li>
            </ul>

          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
