import React, { useEffect } from 'react'

const Disclaimer = () => {

    useEffect(() => {
        var body = document.getElementsByTagName("body");
        body[0].classList.add("bg_remove");

        return () => {
            body[0].classList.remove("bg_remove");
        };
    }, []);

    return (
        <div className='customContainer'>
            <div
                className="disclaimer-data container-fluid mt-5"
            >
                <h1>Disclaimer</h1>
                <p className='topStrong'><strong>Important Notice: Please Read Carefully</strong></p>
                <p style={{ marginBottom: '28px' }}>This document contains important information about the sale of utility tokens (the "Tokens"). By participating in the Token sale, you agree to the following terms and conditions:</p>
                <ol>
                    <li><strong>No Investment Advice</strong>
                        <p>The information provided does not constitute investment advice, financial advice, trading advice, or any other sort of advice. You should not treat any of the website's content as such. You are solely responsible for making your own independent decisions regarding any purchase of Tokens.</p>
                    </li>
                    <li><strong>No Offering of Securities</strong>
                        <p> The Tokens are not intended to constitute securities in any jurisdiction. This document does not constitute a prospectus or offering document and is not an offer to sell or solicit an offer to buy any securities, investment products, regulated products, or financial instruments in any jurisdiction.</p>
                    </li>
                    <li><strong>Risk Disclosure</strong>
                        <p>Purchasing Tokens involves substantial risk, including but not limited to price volatility, lack of liquidity, and the potential loss of principal. You should ensure that you fully understand the risks associated with digital assets. Do not purchase Tokens if you cannot afford to lose the entire amount of your investment.</p>

                    </li>
                    <li><strong>Regulatory Uncertainty</strong>
                        <p>The regulatory status of digital tokens and blockchain technology is unclear or unsettled in many jurisdictions. It is difficult to predict how or whether governmental authorities will regulate such technologies. It is your responsibility to ensure compliance with any relevant laws, regulations, or restrictions in your jurisdiction.</p>

                    </li>
                    <li><strong>No Warranty</strong>
                        <p>The Tokens are provided "as is" without any warranties of any kind, either expressed or implied. The issuer disclaims all warranties, including but not limited to the warranties of merchantability and fitness for a particular purpose.</p>

                    </li>
                    <li><strong>Limitation of Liability</strong>
                        <p>To the fullest extent permitted by applicable law, in no event will the issuer or its affiliates be liable for any damages of any kind, including but not limited to, loss of use, loss of profits, or loss of data, arising out of or in any way connected with the purchase or use of the Tokens.</p>

                    </li>
                    <li><strong>Forward-Looking Statements</strong>
                        <p>This document may contain forward-looking statements, including statements regarding the anticipated development and business of the issuer. These statements are not guarantees of future performance and involve risks and uncertainties. Actual events or results may differ materially from those projected in the forward-looking statements.</p>

                    </li>
                    <li><strong>Amendments</strong>
                        <p>The issuer reserves the right to amend or replace this disclaimer at any time, and it is your responsibility to review this disclaimer periodically for any changes.</p>

                    </li>
                    <li style={{ marginBottom: '28px' }}><strong>Restricted Countries</strong>
                        <p>Citizens or residents of the following countries are not permitted to participate in the Token sale: United States, China, North Korea, Iran, Syria, Sudan, South Sudan, Cuba, and any other jurisdiction where the sale of Tokens is prohibited or restricted by law.</p>

                    </li>
                </ol>
                <p style={{ opacity: '1', fontSize: '18px', fontWeight: '700' }}>By participating in the Token sale, you acknowledge that you have read, understood, and agreed to all the terms and conditions contained in this disclaimer.</p>
            </div>
        </div>
    )
}

export default Disclaimer